import React, { Component } from 'react';
import classNames from 'classnames';

import { dataLayerPush } from '../lib/gtm';
import { Elements, StripeProvider } from 'react-stripe-elements';
import StripeCheckoutForm from '../components/payment/StripeCheckoutForm';
import CheckoutDotComForm from '../components/payment/CheckoutDotComForm';
import { Accordion, Card, Button, Alert } from 'react-bootstrap';

import Footer from '../components/Footer';
import { ReactComponent as LogoColor } from '../assets/images/logo_color.svg';
import paypalLogo from '../assets/images/paypal-button.png';
import * as Sentry from '@sentry/browser';
import history from '../history';
import {
  selectBiller,
  capturePayment,
  chargePayment
} from '../lib/api_service';
import { getProductData } from '../lib/products';

const PlanListItem = props => {
  const colSizeClassName = `col-${props.colSize || '11'}`;

  const elClass = classNames({
    [colSizeClassName]: true,
    sub: props.isTitle,
    'offset-1': !props.isTitle,
    'mb-3': true
  });
  return (
    <div className={elClass}>
      <span>
        <i className={`fa ${props.icon}`} /> {props.title}
      </span>
    </div>
  );
};

const PlanListHeader = props => {
  return <PlanListItem colSize="12" {...props} isTitle={true} />;
};

const stripePubKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const checkoutPubKey = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;
const BILLING_ENV = process.env.REACT_APP_BILLING_ENV;

const paypal = window.paypal;

export default class PaymentPage extends Component {
  state = {
    product: this.loadProduct(),
    customer: { ...this.props.appState.customer },
    paymentType: 'credit',
    paymentStrategy: 'stripe',
    activePaymentType: 'card',
    promoCode: '',
    promoError: '',
    paymentError: '',
    nameError: '',
    isLoading: false,
    isCardValid: false
  };

  fullNameInput = React.createRef();
  paypalButtonContainer = React.createRef();
  stripeFormRef = React.createRef();
  checkoutFormRef = React.createRef();

  // TODO: @refactor Encapsulate Paypal Button in its own component.
  initPaypal() {
    paypal
      .Buttons({
        // FIXME: this should be set by env vars
        env: BILLING_ENV === 'production' ? 'production' : 'sandbox',
        style: {
          layout: 'horizontal', // horizontal | vertical
          size: 'responsive', // medium | large | responsive
          height: 55,
          shape: 'pill', // pill | rect
          color: 'gold', // gold | blue | silver | black
          tagline: 'false',
          fundingicons: 'false'
        },
        funding: {
          allowed: [paypal.FUNDING.CARD],
          disallowed: [paypal.FUNDING.CREDIT]
        },

        createOrder: (data, actions) => {
          this.setState({ isLoading: true });
          return this.capturePayment('paypal', '')
            .then(result => {
              console.log('Created pending Paypal Order:', result);
              this.updateOrderState(result.order);
              return result.order.merchantOrderId;
            })
            .catch(error => {
              this.paymentError(error, error.data.message);
              console.error('Payment Error: ', error.data);
              this.setState({ isLoading: false });
            });
        },

        onApprove: (data, actions) => {
          return this.chargePayment(data.orderID)
            .then(result => {
              console.log('Payment Success: ', result);
              setTimeout(() => {
                this.paymentSuccess(result.order);
              }, 1000);
            })
            .catch(error => {
              this.paymentError(error, error.data.message);
              console.error('Payment Error: ', error.data);
              this.setState({ isLoading: false });
            });
        },

        onCancel: () => {
          this.setState({ isLoading: false });
        }
      })
      .render(this.paypalButtonContainer.current);
  }

  loadProduct() {
    return getProductData('meal_plan', this.props.appState.locale.countryCode);
  }

  componentDidMount() {
    dataLayerPush({ event: 'initiateCheckout' });
    this.selectBiller();

    this.initPaypal();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.appState.locale.countryCode !==
      prevProps.appState.locale.countryCode
    ) {
      this.setState({ product: this.loadProduct() });
    }
  }

  setCustomerName = event => {
    event.preventDefault();
    let customer = this.props.appStateUtils.mergeState('customer', {
      fullName: event.target.value
    });

    this.setState({
      customer: { ...customer }
    });
  };

  selectBiller() {
    return selectBiller()
      .then(res => {
        this.setState({
          // Forcing the strategy to stripe until we figure out how to properly integrate checkout.com
          // paymentStrategy: res.data.billerType
          paymentStrategy: 'stripe'
        });
      })
      .catch(console.error); // TODO: just default here
  }

  async capturePayment(strategy, token) {
    return await capturePayment({
      intent: 'capture',
      billerType: strategy,
      billerToken: token,
      paymentType: 'single',
      productIds: [this.state.product.id],
      customer: {
        fullName: this.state.customer.fullName,
        email: this.state.customer.email
      },
      locale: this.props.appState.locale,
      sessionToken: this.props.sessionToken,
      quiz: this.props.appState.quiz,
      isMealplanPayment: true
    });
  }

  // This is only used by Paypal
  async chargePayment(orderId) {
    const { order } = this.props.appState;
    return await chargePayment({
      intent: 'charge',
      billerType: 'paypal',
      paymentType: 'single',
      orderToken: orderId,
      sessionToken: this.props.sessionToken,
      usePayToken: true,
      payToken: order.payToken,
      quiz: this.props.appState.quiz,
      customer: {
        fullName: this.state.customer.fullName,
        email: this.state.customer.email
      },
      isMealplanPayment: true
    });
  }

  mealplanSuccess(mealplan) {
    this.props.appStateUtils.saveState('mealplan', mealplan);
  }

  updateOrderState(order) {
    let { activePaymentType, paymentStrategy } = this.state;
    paymentStrategy = activePaymentType === 'card' ? paymentStrategy : 'paypal';

    this.props.appStateUtils.mergeState('order', {
      ...order,
      paymentStrategy,
      amount: this.state.product.price
    });
  }

  paymentSuccess(order) {
    this.updateOrderState(order);
    history.push('/special');
  }

  // TODO: @refactor This needs a heavy refactor.
  //   Each payment component should handle its own data and use bound methods to pass data back.
  billMe = async () => {
    let token, result;

    this.setState({ isLoading: true });

    switch (this.state.paymentStrategy) {
      case 'stripe':
        try {
          token = await this.stripeFormRef.current
            .getWrappedInstance()
            .createToken();
        } catch (error) {
          console.error(error);
        }
        break;

      case 'checkout':
        try {
          token = await this.checkoutFormRef.current.requestToken();
        } catch (error) {
          console.error(error);
        }
        break;

      default:
        console.warn('Unknown payment type');
    }

    try {
      if (!token) {
        throw new Error(
          'There was a problem verifying your card. The token is invalid.'
        );
      }
      result = await this.capturePayment(this.state.paymentStrategy, token.id);
      console.log('Payment Success: ', result);
      this.paymentSuccess(result.order);
    } catch (error) {
      let message = error.data
        ? error.data.message.message || error.data.message
        : error.message;
      console.error('Payment Error: ', error.data || message);
      this.paymentError(error, message);
    }

    this.setState({ isLoading: false });

    return;
  };

  submitPayment = async event => {
    const customer = this.state.customer;

    if (!this.state.isCardValid) return;

    // validation
    if (customer.fullName === '') {
      this.setState({ nameError: 'Billing Name is required.' });
    } else {
      this.setState({ nameError: '' });
      this.billMe();
    }
  };

  // this is so we can submit from the parent component, but allow
  // the payment strategy and business logic to be controlled by the payment strategy components
  setSubmitFn = fn => {
    this.submitFn = fn;
  };

  setPaymentType = type => {
    this.setState({ paymentType: type });
  };

  createPaymentForm(strategy) {
    return this.paymentStrategies.get(strategy);
  }

  cardValidationChanged = isValid => {
    this.setState({ isCardValid: isValid });
  };

  setPaymentToken = paymentToken => {
    this.setState({ paymentToken });
  };

  // handleChangePromo = event => {
  //   this.setState({
  //     promoCode: event.target.value
  //   });
  // };

  setPromoErrorMsg = promoError => {
    this.setState({ promoError });
  };

  paymentError = (exception, message) => {
    Sentry.captureException(exception);
    Sentry.captureMessage('sessionToken of error: ', this.props.sessionToken);
    this.setState({
      paymentError: (
        // <div>
        //   We encountered an error processing your payment. Please try again, or
        //   contact us @ 877-925-8009, or&nbsp;
        //   <a href="mailto:support@macrofare.com">support@macrofare.com</a>:
        //   <div>Reference #: {this.props.sessionToken}</div>
        //   <p>{message}</p>
        // </div>
        <React.Fragment>
          <strong>Payment Declined:</strong> {message}
        </React.Fragment>
      )
    });
  };

  // handleApplyPromo() {
  //   this.props.appStateUtils.mergeState( 'order', {
  //     promoCode: this.state.promoCode
  //   });
  // }

  render() {
    this.paymentStrategies = new Map([
      [
        'stripe',
        <StripeProvider apiKey={stripePubKey}>
          <Elements>
            <StripeCheckoutForm
              withRef="true"
              customer={this.state.customer}
              submitPayment={this.submitPayment}
              cardValidationChanged={this.cardValidationChanged}
              ref={this.stripeFormRef}
            />
          </Elements>
        </StripeProvider>
      ],
      [
        'checkout',
        <CheckoutDotComForm
          publicKey={checkoutPubKey}
          setSubmitFn={this.setSubmitFn}
          cardValidationChanged={this.cardValidationChanged}
          setPaymentToken={this.setPaymentToken}
          customer={this.state.customer}
          ref={this.checkoutFormRef}
        />
      ]
    ]);

    let product = this.state.product;
    // TODO: @fix This is related to promo codes.
    // if (product && product.promoErrorMsg) {
    //   promoErrorCodeBlock = (
    //     <span className="span_error has-error">{product.promoErrorMsg}</span>
    //   );
    // }
    var paymentErrorBlock = '';
    if (this.state.paymentError !== '') {
      paymentErrorBlock = (
        <Alert variant="danger">{this.state.paymentError}</Alert>
      );
    }

    var nameErrorBlock = '';
    if (this.state.nameError !== '') {
      nameErrorBlock = (
        <span className="span_error has-error">{this.state.nameError}</span>
      );
    }

    const { language, currencyCode } = this.props.appState.locale;
    const currencyFormatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency: currencyCode
    });
    const { isLoading } = this.state;

    return (
      <div className="quiz-page">
        <section className="banner inner checkout">
          <div className="container">
            <div className="col mt-2 py-3">
              <LogoColor />
            </div>
            <h3 className="text-center mb-2">
              Final Step: <span>Activate Plan</span>
            </h3>
            <p className="text-center pb-4 mb-0">
              Join over <span>{this.props.userbase}</span> users and growing!
            </p>
          </div>
        </section>
        {/* /.banner.inner.checkout */}

        <section className="step-container payment">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-10 col-lg-7 p-md-5 p-sm-0 py-3">
                <div className="review-order">
                  <h3 className="text-uppercase">Review Your Plan:</h3>
                  <p className="offer">1 - Personalized Meal Planner</p>
                  <div className="row no-gutters mb-4 mt-3 order-benefits">
                    <PlanListHeader
                      icon="fa-calendar-check"
                      title={`Recommended Recipes (${this.props
                        .mealPlanWeekLength || ''}-days)`}
                    />
                    <PlanListItem
                      icon="fa-list-alt"
                      title="Recipes for your goal weight"
                    />
                    <PlanListItem
                      icon="fa-utensils"
                      title="Breakfast, lunch, dinner, 2x snacks"
                    />
                    <PlanListItem
                      icon="fa-th"
                      title="Pre-configured serving sizes"
                    />
                    <PlanListItem
                      icon="fa-edit"
                      title="Tailored to your dietary selections"
                    />
                    <PlanListHeader
                      icon="fa-shopping-cart"
                      title="Time-Saving Grocery Lists (Weekly)"
                    />
                    <PlanListItem
                      icon="fa-list-ul"
                      title="Ingredient checklist, totaled for ease"
                    />
                    <PlanListItem
                      icon="fa-clock"
                      title="Categorized by aisle, fast shopping"
                    />
                    <div className="col-12 sub last">
                      <span>
                        <i className="fa fa-bolt" /> Instant Access,
                        Ready-To-Use NOW!
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {/* <div
                        className="input-group my-3"
                        style={{
                          overflow: 'hidden',
                          width: '100%'
                        }}
                      >
                        <input
                          id="promo_code"
                          onChange={this.handleChangePromo}
                          type="text"
                          className={
                            'form-control promo-code ' +
                            (this.state.promoError !== ''
                              ? 'input_has_error'
                              : '')
                          }
                          placeholder="Promo Code"
                          value={this.state.promoCode}
                        />
                        <div className="input-group-append">
                          <button
                            onClick={event => {
                              event.preventDefault();
                              this.handleApplyPromo();
                            }}
                            className="btn btn-info px-5 promo-code-apply"
                            type="button"
                            style={{
                              background: '#00c297',
                              color: '#fff !important',
                              padding: '0 30px !important',
                              borderColor: '#00c297 !important'
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      {promoErrorCodeBlock} */}
                    </div>
                    <div className="col-3 col-md-5 col-sm-3">
                      <h3 className="text-right">Total:</h3>
                    </div>
                    <div className="col-9 col-md-7 col-sm-9">
                      <h1
                        className="offer-price mb-0"
                        style={{
                          textTransform: 'uppercase'
                        }}
                      >
                        <span className="country-price-main">
                          {currencyFormatter.format(product.price)}
                        </span>
                        <span
                          className="text-muted h4 font-weight-light"
                          style={{
                            textDecoration: 'line-through'
                          }}
                        >
                          {currencyFormatter.format(product.priceBefore)}
                        </span>
                      </h1>
                      <p className="offer-text mt-0 mb-3">one-time payment</p>
                    </div>
                  </div>
                  <div className="row no-gutters mb-4 mt-3 order-benefits billing-terms">
                    <div className="offset-2 col-md-7 offset-md-5 col-sm-6 mb-3">
                      <span>
                        <i className="far fa-check-square" /> NO hidden fees
                      </span>
                    </div>
                    <div className="offset-2 col-md-7 offset-md-5 col-sm-6 mb-3">
                      <span>
                        <i className="far fa-check-square" /> NO subscription
                        billing
                      </span>
                    </div>
                    <div className="offset-2 col-md-7 offset-md-5 col-sm-6">
                      <span>
                        <i className="far fa-check-square" /> LIFETIME access to
                        meal plan
                      </span>
                    </div>
                  </div>
                </div>
                {/* /.billing-terms */}

                {/* TODO: this will be an ajax call and a transition to a route on success or possibly state change on error */}
                <div
                  id="payment-form"
                  className="form-left-align payment-accordion"
                >
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          eventKey="0"
                          className="btn-link btn"
                          aria-expanded={
                            this.state.activePaymentType === 'card'
                          }
                          onClick={() => {
                            this.setState({
                              activePaymentType: 'card',
                              paymentError: ''
                            });
                          }}
                        >
                          <h4 className="mb-0 d-inline-block">Credit/Debit:</h4>
                          <h2
                            className="float-right mb-0"
                            style={{
                              fontWeight: '300',
                              margin: '0'
                            }}
                          >
                            <i className="fab fa-cc-visa" />
                            <i className="fab fa-cc-mastercard ml-1" />
                            <i className="fab fa-cc-amex ml-1" />
                            <i className="fab fa-cc-discover ml-1" />
                          </h2>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <div className="card-body">
                          {/* <!-- Used to display form errors. --> */}
                          {paymentErrorBlock}
                          <div
                            className="alert alert-success div_payment_success"
                            role="alert"
                            style={{
                              display: 'none'
                            }}
                          >
                            <strong>
                              Congratulations, Payment Successful.
                            </strong>
                          </div>
                          <div className="form-group">
                            <label htmlFor="card-name">Billing Name:</label>
                            <input
                              className={
                                'form-control ' +
                                (this.state.nameError !== ''
                                  ? 'input_has_error'
                                  : '')
                              }
                              type="text"
                              id="card-name"
                              name="full_name"
                              placeholder="Full name on card"
                              ref={this.fullNameInput}
                              value={this.state.customer.fullName}
                              onChange={this.setCustomerName}
                            />
                            <span
                              className="span_error"
                              id="span_error_card-name"
                            >
                              Billing Name is required
                            </span>
                            {nameErrorBlock}
                          </div>
                          <div className="form-group">
                            <label htmlFor="card-name">Card Number:</label>
                            <div id="card-element" className="center">
                              {this.createPaymentForm(
                                this.state.paymentStrategy
                              )}

                              {/* <!-- A Stripe or Checkout.com Element will be inserted here. --> */}
                            </div>
                          </div>

                          <div className="row justify-content-center button-wrap mt-4">
                            <div className="col-md-10">
                              {/* ALFIE: I comment out this for now until this new approach is approved. */}
                              {/* <button
                                className="button-styled w-100 btn-lg payment-btn"
                                onClick={this.submitPayment}
                                // disabled={!this.state.isPaymentValid}
                              >
                                Activate Plan <i className="fa fa-lock" />
                              </button> */}
                              <Button
                                className="button-styled w-100 btn-lg payment-btn"
                                disabled={isLoading}
                                onClick={!isLoading ? this.submitPayment : null}
                              >
                                {isLoading ? (
                                  <React.Fragment>
                                    <i className="fas fa-circle-notch fa-spin" />{' '}
                                    Processing Order...
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    Activate Plan <i className="fa fa-lock" />
                                  </React.Fragment>
                                )}
                              </Button>
                            </div>
                          </div>
                          <div className="card-details-https">
                            <p className="mb-0">
                              <span>
                                <i className="fa fa-lock" /> 256-bit SSL -
                                macrofare.com
                              </span>
                            </p>
                            <p className="mb-0">100% secure online payments</p>
                          </div>
                        </div>
                      </Accordion.Collapse>
                    </Card>

                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          eventKey="1"
                          className="btn btn-link"
                          aria-expanded={
                            this.state.activePaymentType === 'paypal'
                          }
                          onClick={() => {
                            this.setState({
                              activePaymentType: 'paypal',
                              paymentError: ''
                            });
                          }}
                        >
                          <React.Fragment>
                            <h4 className="mb-0 d-inline-block">PayPal:</h4>
                            <img height="48px" src={paypalLogo} alt="" />
                          </React.Fragment>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <div className="card-body">
                            {/* <!-- Used to display form errors. --> */}
                            <div id="card-errors" role="alert">
                              {paymentErrorBlock}
                            </div>

                            <div id="paypal-outer-container">
                              {isLoading ? (
                                <React.Fragment>
                                  <div style={{ textAlign: 'center' }}>
                                    <i className="fas fa-circle-notch fa-spin" />{' '}
                                    Processing Order...
                                  </div>
                                </React.Fragment>
                              ) : null}
                              <div
                                id="paypal-container"
                                ref={this.paypalButtonContainer}
                                style={{
                                  display: isLoading ? 'none' : 'block'
                                }}
                              />
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </Card.Header>
                    </Card>
                  </Accordion>

                  {/* /payment-form */}
                </div>
                {/* <!-- .review-order --> */}
              </div>
              {/* <!-- .col --> */}
            </div>
            {/* <!-- .row --> */}
            <h3 className="center mt-5">
              Have a question? Feel free to contact us by e-mail:
              support@macrofare.com
            </h3>
          </div>
          {/* <!-- .container --> */}
        </section>

        <Footer hideDisclaimer invertColor />
      </div>
    );
  }
}
